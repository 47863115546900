import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import api from "./http/index";
import qs from "qs"; // 引入qs模块，用来序列化post类型的数据
import'./style/base.scss'
import './assets/font/font.css'
Vue.prototype.$api = api;
Vue.prototype.$QS = qs;
Vue.config.productionTip = false
Vue.use(ElementUI);
new Vue({
  render: h => h(App),
}).$mount('#app')
