/**
 * http.js
 * 请求
 * 
 */
import axios from 'axios'
// 创建 axios 实例
const service = axios.create({
  // 配置项
  baseURL:"http://10.0.2.198:8080/",
  timeout: 30000,
  // 在向服务器发送请求前，序列化请求数据
    transformRequest: [function (data) {
        data = JSON.stringify(data)
        return data
    }],
    // 在传递给 then/catch 前，修改响应数据
    transformResponse: [function (data) {
        if (typeof data === 'string' && data.startsWith('{')) {
            data = JSON.parse(data)
        }
        return data
    }]
})

export default service