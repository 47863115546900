<template>
  <div id="app">
    <div class="header">
      <div class="header-content">
        <img src="./assets/img/pc.png" alt="" />
      </div>
    </div>
    <div class="media-kit">
      <p>Media Kit</p>
    </div>
    <HelloWorld msg="Welcome to Your Vue.js App" />
  </div>
</template>

<script>
import HelloWorld from "./components/HelloWorld.vue";
export default {
  name: "App",
  methods: {},
  components: {
    HelloWorld,
  },
};
</script>

<style lang="scss">
@media screen and (max-width: 600px) {
  #app {
    background-image: url("./assets/img/bg-3@2x.png");
    .header-content {
      justify-content: center;
    }
  }
}
@media screen and (min-width: 601px) and (max-width: 1023px) {
  #app {
    background-image: url("./assets/img/iPad-b.png");
  }
}
@media screen and (min-width: 1024px) and (max-width: 1365px) {
  #app {
    background-image: url("./assets/img/iPad-b.png");
  }
}
@media screen and (min-width: 1366px) {
  #app {
    background-image: url("./assets/img/bg-web-a.png");
  }
}
#app {
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  // color: #2c3e50;
  background-attachment: fixed;
  width: 100%;
  min-height: 100%;
  // height: 100%;
  // background-image: url("./assets/img/bg-web.jpg");
  background-position: center center;
  // background-size: 800% 80%;
  background-size: cover;
  background-repeat: no-repeat;
  .header {
    box-sizing: border-box;
    background-color: #403f4f;
    height: 80px;
    .header-content {
      box-sizing: border-box;
      margin: 0 auto;
      max-width: 1060px;
      display: flex;
      height: 100%;
      align-items: center;
      img {
        padding-left: 24px;
      }
    }
  }
  .media-kit {
    box-sizing: border-box;
    padding: 20px 24px;
    max-width: 1060px;
    margin: 0 auto;
    font-weight: 500;
    font-family: "SemiBold", "PingFang-SC-Medium", "PingFang-SC";
    p {
      color: #23201e;
      font-size: 20px;
      line-height: 28px;
    }
  }
}
</style>
