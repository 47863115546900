<template>
  <div class="box">
    <div class="content">
      <div class="table-wrap">
        <el-table
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          size="medium"
          :row-key="getRowKeys"
          style="width: 100%; font-size: 16px; color: #333333"
          @selection-change="handleSelectionChange"
          @select="selectFun"
          @select-all="selectAllFun"
          :header-cell-style="{ background: '#F6F6F6', color: '#919398' }"
        >
          <el-table-column
            type="selection"
            align="center"
            min-width="40%"
            class-name="select-box"
          >
          </el-table-column>
          <!-- <el-table-column label="文件名" width="120">
          <template slot-scope="scope">{{ scope.row.date }}</template>
        </el-table-column> -->
          <el-table-column label="File Name">
            <template slot-scope="scope">
              <span v-html="scope.row.name"></span>
            </template>
          </el-table-column>
          <el-table-column
            prop="fileSize"
            label="Size"
            width="100"
            align="center"
            show-overflow-tooltip
          >
          </el-table-column>
        </el-table>
      </div>
      <div class="button-wrap">
        <el-button type="primary" @click="downFile()">Download</el-button>
        <!-- <el-button @click="toggleSelection([tableData[1], tableData[2]])"
        >切换第二、第三行的选中状态</el-button
      > -->
        <!-- <el-button type="primary" @click="toggleSelection()"
          >取消选择</el-button
        > -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [
        {
          id: "eboPhoto",
          url: "",
          name: "EBO SE & AIR - Scenario Photos",
          fileSize: "199.56MB",
          type: "jpg",
          parentIndex: 1,
          moreList: true,
          children: [
            {
              id: "ebo10",
              url:
                "https://mediakit.enabot.com/mediakitV2/EBO_SE%26AIR_MediaKit/EBO%20SE%20&%20AIR%20-%20Scenario%20Photos/EBO%20AIR%20-%20close%20up.jpg",
              name: "EBO AIR - close up.jpg",
              fileSize: "10.5MB",
              parentId: "eboPhoto",
              parentIndex: 2,
              type: "jpg",
            },
            {
              id: "ebo11",
              url:
                "https://mediakit.enabot.com/mediakitV2/EBO_SE%26AIR_MediaKit/EBO%20SE%20&%20AIR%20-%20Scenario%20Photos/EBO%20AIR%20-%20living%20room%20-1.jpg",
              name: "EBO AIR - living room -1.jpg",
              fileSize: "14MB",
              parentId: "eboPhoto",
              parentIndex: 2,
              type: "jpg",
            },
            {
              id: "ebo12",
              url:
                "https://mediakit.enabot.com/mediakitV2/EBO_SE%26AIR_MediaKit/EBO%20SE%20&%20AIR%20-%20Scenario%20Photos/EBO%20AIR%20-%20living%20room%20-2.jpg",
              name: "EBO AIR - living room -2.jpg",
              fileSize: "12.4MB",
              parentId: "eboPhoto",
              parentIndex: 2,
              type: "jpg",
            },
            {
              id: "ebo13",
              url:
                "https://mediakit.enabot.com/mediakitV2/EBO_SE%26AIR_MediaKit/EBO%20SE%20&%20AIR%20-%20Scenario%20Photos/EBO%20AIR%20-%20with%20cat.jpg",
              name: "EBO AIR - with cat.jpg",
              fileSize: "24.4MB",
              parentId: "eboPhoto",
              parentIndex: 2,
              type: "jpg",
            },
            {
              id: "ebo14",
              url:
                "https://mediakit.enabot.com/mediakitV2/EBO_SE%26AIR_MediaKit/EBO%20SE%20&%20AIR%20-%20Scenario%20Photos/EBO%20AIR%20-%20with%20child%20-1.jpg",
              name: "EBO AIR - with child -1.jpg",
              fileSize: "18.2MB",
              parentId: "eboPhoto",
              parentIndex: 2,
              type: "jpg",
            },
            {
              id: "ebo15",
              url:
                "https://mediakit.enabot.com/mediakitV2/EBO_SE%26AIR_MediaKit/EBO%20SE%20&%20AIR%20-%20Scenario%20Photos/EBO%20AIR%20-%20with%20child%20-2.jpg",
              name: "EBO AIR - with child -2.jpg",
              fileSize: "17.1MB",
              parentId: "eboPhoto",
              parentIndex: 2,
              type: "jpg",
            },
            {
              id: "ebo16",
              url:
                "https://mediakit.enabot.com/mediakitV2/EBO_SE%26AIR_MediaKit/EBO%20SE%20&%20AIR%20-%20Scenario%20Photos/EBO%20AIR%20-%20with%20dad.jpg",
              name: "EBO AIR - with dad.jpg",
              fileSize: "11.3MB",
              parentId: "eboPhoto",
              parentIndex: 2,
              type: "jpg",
            },
            {
              id: "ebo17",
              url:
                "https://mediakit.enabot.com/mediakitV2/EBO_SE%26AIR_MediaKit/EBO%20SE%20&%20AIR%20-%20Scenario%20Photos/EBO%20AIR%20-%20with%20grandma.jpg",
              name: "EBO AIR - with grandma.jpg",
              fileSize: "18.2MB",
              parentId: "eboPhoto",
              parentIndex: 2,
              type: "jpg",
            },
            {
              id: "ebo18",
              url:
                "https://mediakit.enabot.com/mediakitV2/EBO_SE%26AIR_MediaKit/EBO%20SE%20&%20AIR%20-%20Scenario%20Photos/EBO%20SE%20-%20bedroom.jpg",
              name: "EBO SE - bedroom.jpg",
              fileSize: "9.12MB",
              parentId: "eboPhoto",
              parentIndex: 2,
              type: "jpg",
            },
            {
              id: "ebo19",
              url:
                "https://mediakit.enabot.com/mediakitV2/EBO_SE%26AIR_MediaKit/EBO%20SE%20&%20AIR%20-%20Scenario%20Photos/EBO%20SE%20-%20living%20room%20with%20mom%20-1.jpg",
              name: "EBO SE - living room with mom -1.jpg",
              fileSize: "11.4MB",
              parentId: "eboPhoto",
              parentIndex: 2,
              type: "jpg",
            },
            {
              id: "ebo20",
              url:
                "https://mediakit.enabot.com/mediakitV2/EBO_SE%26AIR_MediaKit/EBO%20SE%20&%20AIR%20-%20Scenario%20Photos/EBO%20SE%20-%20living%20room%20with%20mom%20-2.jpg",
              name: "EBO SE - living room with mom -2.jpg",
              fileSize: "9.16MB",
              parentId: "eboPhoto",
              parentIndex: 2,
              type: "jpg",
            },
            {
              id: "ebo21",
              url:
                "https://mediakit.enabot.com/mediakitV2/EBO_SE%26AIR_MediaKit/EBO%20SE%20&%20AIR%20-%20Scenario%20Photos/EBO%20SE%20-%20living%20room.jpg",
              name: "EBO SE - living room.jpg",
              fileSize: "12.3MB",
              parentId: "eboPhoto",
              parentIndex: 2,
              type: "jpg",
            },
            {
              id: "ebo22",
              url:
                "https://mediakit.enabot.com/mediakitV2/EBO_SE%26AIR_MediaKit/EBO%20SE%20&%20AIR%20-%20Scenario%20Photos/EBO%20SE%20-%20whats%20in%20the%20box.jpg",
              name: "EBO SE - whats in the box.jpg",
              fileSize: "7.18MB",
              parentId: "eboPhoto",
              parentIndex: 2,
              type: "jpg",
            },
            {
              id: "ebo23",
              url:
                "https://mediakit.enabot.com/mediakitV2/EBO_SE%26AIR_MediaKit/EBO%20SE%20&%20AIR%20-%20Scenario%20Photos/EBO%20SE%20-%20with%20cat.jpg",
              name: "EBO SE - with cat.jpg",
              fileSize: "24.3MB",
              parentId: "eboPhoto",
              parentIndex: 2,
              type: "jpg",
            },
          ],
        },
        {
          id: "ebovideo",
          url: "",
          name: "EBO SE & AIR - Videos",
          fileSize: "1052MB",
          type: "pdf",
          parentIndex: 1,
          moreList: true,
          children: [
            {
              id: "ebo3",
              url:
                "https://mediakit.enabot.com/mediakitV2/EBO_SE%26AIR_MediaKit/EBO%20SE%20&%20AIR%20-%20Videos/EBO%20SE%20Unboxing.mp4",
              name: "EBO SE Unboxing.mp4",
              fileSize: "527MB",
              parentId: "ebovideo",
              parentIndex: 2,
              type: "mp4",
            },
            {
              id: "ebo4",
              url:
                "https://mediakit.enabot.com/mediakitV2/EBO_SE%26AIR_MediaKit/EBO%20SE%20&%20AIR%20-%20Videos/Introducing%20the%20EBO%20AIR%20and%20SE.mp4",
              name: "Introducing the EBO AIR and SE.mp4",
              fileSize: "381MB",
              parentId: "ebovideo",
              parentIndex: 2,
              type: "mp4",
            },
            {
              id: "ebo5",
              url:
                "https://mediakit.enabot.com/mediakitV2/EBO_SE%26AIR_MediaKit/EBO%20SE%20&%20AIR%20-%20Videos/Our%20Vision%20-%20Always%20Together.mp4",
              name: "Our Vision - Always Together.mp4",
              fileSize: "144MB",
              parentId: "ebovideo",
              parentIndex: 2,
              type: "mp4",
            },
            {
              id: "ebo7",
              url:
                "https://mediakit.enabot.com/mediakitV2/EBO_SE%26AIR_MediaKit/EBO%20SE%20&%20AIR%20-%20Videos/Subtitles/EBO%20SE%20Unboxing_ENGSUB.srt",
              name: "EBO SE Unboxing_ENGSUB.srt",
              fileSize: "10KB",
              parentId: "ebovideo",
              parentIndex: 2,
              type: "srt",
            },
            {
              id: "ebo8",
              url:
                "https://mediakit.enabot.com/mediakitV2/EBO_SE%26AIR_MediaKit/EBO%20SE%20&%20AIR%20-%20Videos/Subtitles/Introducing%20the%20EBO%20AIR%20and%20SE_ENGSUB.srt",
              name: "Introducing the EBO AIR and SE_ENGSUB.srt",
              fileSize: "5KB",
              parentId: "ebovideo",
              parentIndex: 2,
              type: "srt",
            },
            {
              id: "ebo9",
              url:
                "https://mediakit.enabot.com/mediakitV2/EBO_SE%26AIR_MediaKit/EBO%20SE%20&%20AIR%20-%20Videos/Subtitles/Our%20Vision%20-%20Always%20Together_ENGSUB.srt",
              name: "Our Vision - Always Together_ENGSUB.srt",
              fileSize: "2KB",
              parentId: "ebovideo",
              parentIndex: 2,
              type: "srt",
            },
          ],
        },
        // {
        //   id: "ebo1",
        //   parentIndex: 1,
        //   url:
        //     "https://mediakit.enabot.com/mediakitV2/EBO_SE%26AIR_MediaKit/EBO+Flyer_EN.pdf",
        //   name: "EBO+Flyer_EN.pdf",
        //   fileSize: "14.3MB",
        //   type: "pdf",
        // },
        // {
        //   id: "ebo2",
        //   url:
        //     "https://mediakit.enabot.com/mediakitV2/EBO_SE%26AIR_MediaKit/EBO%20SE%20&%20EBO%20AIR%20Press%20Release.pdf",
        //   name: "EBO SE & EBO AIR Press Release.pdf",
        //   fileSize: "39KB",
        //   parentIndex: 1,
        //   type: "pdf",
        // },
        // {
        //   id: "ebo3",
        //   url:
        //     "https://mediakit.enabot.com/mediakitV2/EBO_SE%26AIR_MediaKit/EBO%20SE%20&%20AIR%20-%20Videos/EBO%20SE%20Unboxing.mp4",
        //   name: "EBO SE Unboxing.mp4",
        //   fileSize: "527MB",
        //   type: "mp4",
        // },
        // {
        //   id: "ebo4",
        //   url:
        //     "https://mediakit.enabot.com/mediakitV2/EBO_SE%26AIR_MediaKit/EBO%20SE%20&%20AIR%20-%20Videos/Introducing%20the%20EBO%20AIR%20and%20SE.mp4",
        //   name: "Introducing the EBO AIR and SE.mp4",
        //   fileSize: "381MB",
        //   type: "mp4",
        // },
        // {
        //   id: "ebo5",
        //   url:
        //     "https://mediakit.enabot.com/mediakitV2/EBO_SE%26AIR_MediaKit/EBO%20SE%20&%20AIR%20-%20Videos/Our%20Vision%20-%20Always%20Together.mp4",
        //   name: "Our Vision - Always Together.mp4",
        //   fileSize: "144MB",
        //   type: "mp4",
        // },
        // {
        //   id: "ebo6",
        //   url:
        //     "https://mediakit.enabot.com/mediakitV2/EBO_SE%26AIR_MediaKit/EBO%20SE%20&%20AIR%20-%20Videos/Our%20Vision%20-%20Always%20Together.mp4",
        //   name: "Our Vision - Always Together.mp4",
        //   fileSize: "144MB",
        //   type: "mp4",
        // },

        // {
        //   id: "ebo10",
        //   url:
        //     "https://mediakit.enabot.com/mediakitV2/EBO_SE%26AIR_MediaKit/EBO%20SE%20&%20AIR%20-%20Scenario%20Photos/EBO%20AIR%20-%20close%20up.jpg",
        //   name: "EBO AIR - close up.jpg",
        //   fileSize: "10.5MB",
        //   type: "jpg",
        // },
        // {
        //   id: "ebo11",
        //   url:
        //     "https://mediakit.enabot.com/mediakitV2/EBO_SE%26AIR_MediaKit/EBO%20SE%20&%20AIR%20-%20Scenario%20Photos/EBO%20AIR%20-%20living%20room%20-1.jpg",
        //   name: "EBO AIR - living room -1.jpg",
        //   fileSize: "14MB",
        //   type: "jpg",
        // },
        // {
        //   id: "ebo12",
        //   url:
        //     "https://mediakit.enabot.com/mediakitV2/EBO_SE%26AIR_MediaKit/EBO%20SE%20&%20AIR%20-%20Scenario%20Photos/EBO%20AIR%20-%20living%20room%20-2.jpg",
        //   name: "EBO AIR - living room -2.jpg",
        //   fileSize: "12.4MB",
        //   type: "jpg",
        // },
        // {
        //   id: "ebo13",
        //   url:
        //     "https://mediakit.enabot.com/mediakitV2/EBO_SE%26AIR_MediaKit/EBO%20SE%20&%20AIR%20-%20Scenario%20Photos/EBO%20AIR%20-%20with%20cat.jpg",
        //   name: "EBO AIR - with cat.jpg",
        //   fileSize: "24.4MB",
        //   type: "jpg",
        // },
        // {
        //   id: "ebo14",
        //   url:
        //     "https://mediakit.enabot.com/mediakitV2/EBO_SE%26AIR_MediaKit/EBO%20SE%20&%20AIR%20-%20Scenario%20Photos/EBO%20AIR%20-%20with%20child%20-1.jpg",
        //   name: "EBO AIR - with child -1.jpg",
        //   fileSize: "18.2MB",
        //   type: "jpg",
        // },
        // {
        //   id: "ebo15",
        //   url:
        //     "https://mediakit.enabot.com/mediakitV2/EBO_SE%26AIR_MediaKit/EBO%20SE%20&%20AIR%20-%20Scenario%20Photos/EBO%20AIR%20-%20with%20child%20-2.jpg",
        //   name: "EBO AIR - with child -2.jpg",
        //   fileSize: "17.1MB",
        //   type: "jpg",
        // },
        // {
        //   id: "ebo16",
        //   url:
        //     "https://mediakit.enabot.com/mediakitV2/EBO_SE%26AIR_MediaKit/EBO%20SE%20&%20AIR%20-%20Scenario%20Photos/EBO%20AIR%20-%20with%20dad.jpg",
        //   name: "EBO AIR - with dad.jpg",
        //   fileSize: "11.3MB",
        //   type: "jpg",
        // },
        // {
        //   id: "ebo17",
        //   url:
        //     "https://mediakit.enabot.com/mediakitV2/EBO_SE%26AIR_MediaKit/EBO%20SE%20&%20AIR%20-%20Scenario%20Photos/EBO%20AIR%20-%20with%20grandma.jpg",
        //   name: "EBO AIR - with grandma.jpg",
        //   fileSize: "18.2MB",
        //   type: "jpg",
        // },
        // {
        //   id: "ebo18",
        //   url:
        //     "https://mediakit.enabot.com/mediakitV2/EBO_SE%26AIR_MediaKit/EBO%20SE%20&%20AIR%20-%20Scenario%20Photos/EBO%20SE%20-%20bedroom.jpg",
        //   name: "EBO SE - bedroom.jpg",
        //   fileSize: "9.12MB",
        //   type: "jpg",
        // },
        // {
        //   id: "ebo19",
        //   url:
        //     "https://mediakit.enabot.com/mediakitV2/EBO_SE%26AIR_MediaKit/EBO%20SE%20&%20AIR%20-%20Scenario%20Photos/EBO%20SE%20-%20living%20room%20with%20mom%20-1.jpg",
        //   name: "EBO SE - living room with mom -1.jpg",
        //   fileSize: "11.4MB",
        //   type: "jpg",
        // },
        // {
        //   id: "ebo20",
        //   url:
        //     "https://mediakit.enabot.com/mediakitV2/EBO_SE%26AIR_MediaKit/EBO%20SE%20&%20AIR%20-%20Scenario%20Photos/EBO%20SE%20-%20living%20room%20with%20mom%20-2.jpg",
        //   name: "EBO SE - living room with mom -2.jpg",
        //   fileSize: "9.16MB",
        //   type: "jpg",
        // },
        // {
        //   id: "ebo21",
        //   url:
        //     "https://mediakit.enabot.com/mediakitV2/EBO_SE%26AIR_MediaKit/EBO%20SE%20&%20AIR%20-%20Scenario%20Photos/EBO%20SE%20-%20living%20room.jpg",
        //   name: "EBO SE - living room.jpg",
        //   fileSize: "12.3MB",
        //   type: "jpg",
        // },
        // {
        //   id: "ebo22",
        //   url:
        //     "https://mediakit.enabot.com/mediakitV2/EBO_SE%26AIR_MediaKit/EBO%20SE%20&%20AIR%20-%20Scenario%20Photos/EBO%20SE%20-%20whats%20in%20the%20box.jpg",
        //   name: "EBO SE - whats in the box.jpg",
        //   fileSize: "7.18MB",
        //   type: "jpg",
        // },
        // {
        //   id: "ebo23",
        //   url:
        //     "https://mediakit.enabot.com/mediakitV2/EBO_SE%26AIR_MediaKit/EBO%20SE%20&%20AIR%20-%20Scenario%20Photos/EBO%20SE%20-%20with%20cat.jpg",
        //   name: "EBO SE - with cat.jpg",
        //   fileSize: "24.3MB",
        //   type: "jpg",
        // },
      ],
      multipleSelection: [],
    };
  },
  mounted() {
    //需要
    // this.tableData = this.tableData.sort((a, b) => {
    //   let aFileName = a.name.substr(a.name.lastIndexOf(".") + 1);
    //   let bFileName = b.name.substr(b.name.lastIndexOf(".") + 1);
    //   return aFileName.charCodeAt(0) - bFileName.charCodeAt(0);
    // });
    this.initData(this.tableData);
  },
  methods: {
    getRowKeys(row) {
      return row.id;
    },
    initData(data) {
      data.forEach((item) => {
        item.isSelect = false; //默认为不选中
        if (item.children && item.children.length) {
          this.initData(item.children);
        }
      });
    },
    selectFun(selection, row) {
      // console.log("selectFun:selection", selection);
      // console.log("selectFun:row", row);
      this.setRowIsSelect(row);
    },
    setRowIsSelect(row) {
      if (row.isSelect == false && row.parentIndex == 1) {
        row.isSelect = true;
        this.$refs.multipleTable.toggleRowSelection(row, true);
        this.$refs.multipleTable.toggleRowExpansion(row, true);
        if (row.moreList) {
          this.selectChildrenAll(row, true);
        }
      } else if (row.isSelect == true && row.parentIndex == 1) {
        row.isSelect = false;
        this.$refs.multipleTable.toggleRowSelection(row, false);
        this.$refs.multipleTable.toggleRowExpansion(row, false);
        if (row.moreList) {
          this.selectChildrenAll(row, false);
        }
      } else if (row.isSelect == false && row.parentIndex == 2) {
        //子级选中后
        row.isSelect = true;
        this.$refs.multipleTable.toggleRowSelection(row, true);
        //子级选中后，如果父节点的子字节点都是选中状态，则父节点选中
        let partentRow = this.getExplicitNode(row, this.tableData);
        let sureAll = partentRow.children.every((selectItem) => {
          return selectItem.isSelect === true;
        });
        if (sureAll) {
          partentRow.isSelect = true;
          this.$refs.multipleTable.toggleRowSelection(partentRow, true);
        }
      } else if (row.isSelect == true && row.parentIndex == 2) {
        //如果父节点的子字节点都是选中状态，则父节点去掉选中状态
        let partentRow = this.getExplicitNode(row, this.tableData);
        let sureAll = partentRow.children.every((selectItem) => {
          return selectItem.isSelect === true;
        });
        if (sureAll) {
          partentRow.isSelect = false;
          this.$refs.multipleTable.toggleRowSelection(partentRow, false);
        }
        //子级去掉选中
        row.isSelect = false;
        this.$refs.multipleTable.toggleRowSelection(row, false);
      }
      // if (row.isSelect == true) {
      //   row.isSelect = true;
      // }
    },
    //获取父节点
    getExplicitNode(row, data) {
      let result = {};
      data.forEach((item) => {
        if (item.id == row.parentId) {
          result = item;
        }
      });
      return result;
    },
    selectChildrenAll(row, isSure) {
      row.children.forEach((item) => {
        item.isSelect = isSure;
        this.$refs.multipleTable.toggleRowSelection(item, isSure);
      });
    },
    selectAllFun(selection) {
      // console.log("selectAllFun", selection);
      let oneProductIsSelect = [];
      this.tableData.forEach((item) => {
        oneProductIsSelect.push(item.isSelect);
      });
      let isAllSelect = oneProductIsSelect.every((selectStatusItem) => {
        return true == selectStatusItem;
      });

      this.tableData.forEach((item) => {
        item.isSelect = isAllSelect;
        this.$refs.multipleTable.toggleRowSelection(item, !isAllSelect);
        this.selectFun(selection, item);
      });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    tableRowClassName({ row }) {
      if (this.multipleSelection.includes(row)) {
        return "rowSelect";
      } else {
        return "";
      }
      // row.map((item) => {
      //   this.indexAt.push(item.id);
      // });
      // console.log("rowIndex", rowIndex);
      // console.log("this.indexAt", this.indexAt);
      // console.log("this.multipleSelection", this.multipleSelection);

      // if (
      //   this.multipleSelection.findIndex((item) => {
      //     console.log("111", item.id);
      //     return item.id === row.id;
      //   })
      // ) {
      //   return { background: "#403f4f" };
      // }
      // if (this.multipleSelection.includes(row)) {
      //   return { background: "#403f4f" };
      // }
    },
    // sortByFileName(a, b) {
    //   let aFileName = a.name.substr(a.name.lastIndexOf(".") + 1);
    //   let bFileName = b.name.substr(b.name.lastIndexOf(".") + 1);
    //   return aFileName.charCodeAt(0) - bFileName.charCodeAt(0);
    // },
    // changeTableSort(value) {
    //   console.log(value);
    //   if (value.order == "ascending") {
    //     this.multipleSelection = this.multipleSelection.sort((a, b) => {
    //       let aFileName = a.name.substr(a.name.lastIndexOf(".") + 1);
    //       let bFileName = b.name.substr(b.name.lastIndexOf(".") + 1);
    //       return aFileName.charCodeAt(0) - bFileName.charCodeAt(0);
    //     });
    //   } else {
    //     this.multipleSelection = this.multipleSelection.sort((a, b) => {
    //       let aFileName = a.name.substr(a.name.lastIndexOf(".") + 1);
    //       let bFileName = b.name.substr(b.name.lastIndexOf(".") + 1);
    //       return bFileName.charCodeAt(0) - aFileName.charCodeAt(0);
    //     });
    //   }
    // },
    downFile() {
      // console.log(this.multipleSelection);
      // this.$api
      //   .get("123.xlsx", {
      //     responseType: "blob",
      //     headers: { "Content-Type": "application/xhtml+xml" },
      //   })
      //   .then((res) => {
      //     console.log(res);
      //   });

      // if (this.multipleSelection.length === 0) return;
      // for (let i = 0; i < this.multipleSelection.length; i++) {
      //   let link = document.createElement("a");
      //   link.style.display = "none";
      //   link.href = this.multipleSelection[i].url;
      //   link.setAttribute("download", this.multipleSelection[i].name);
      //   document.body.appendChild(link);
      //   link.click();
      // }
      // this.$refs.multipleTable.clearSelection();

      let arrayDown = [];
      this.multipleSelection.map((item) => {
        if (item.moreList) {
          arrayDown = [...arrayDown, ...item.children];
        }
        arrayDown.push(item);
      });

      arrayDown = [...new Set(arrayDown)];
      if (arrayDown.length === 0) return;
      for (let i = 0; i < arrayDown.length; i++) {
        if (arrayDown[i].url) {
          const iframe = document.createElement("iframe");
          iframe.style.display = "none";
          iframe.style.height = 0;
          iframe.dow = arrayDown[i].name;
          iframe.src = arrayDown[i].url;
          document.body.appendChild(iframe);
          setTimeout(() => {
            iframe.remove();
          }, 30 * 60 * 1000);
        }
      }
      this.initData(this.tableData);
      this.$refs.multipleTable.clearSelection();
    },
  },
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 600px) {
  .button-wrap {
    text-align: center;
    margin-top: 30px;
  }
  // /deep/.select-box {
  //   margin-left: 10px;
  //   margin-right: 10px;
  // }
}
@media screen and (min-width: 601px) {
  .button-wrap {
    margin-top: 30px;
    margin-left: 24px;
  }
  // /deep/.select-box {
  //   margin-left: 20px;
  //   margin-right: 20px;
  // }
}
.box {
  width: 100%;

  display: flex;
  justify-content: center;
  .content {
    max-width: 1060px;
    width: 100%;

    // min-width: 335px;
    background: #ffffff;
    display: flex;
    // justify-content: center;
    flex-direction: column;
    // align-items: center;
    // margin: 0px auto;
    padding-bottom: 30px;
    border-radius: 8px;
    .table-wrap {
      margin: 0 24px;
    }
    /deep/ .el-table__header .cell {
      font-family: "SemiBold";
    }
    /deep/ .el-table__body .cell {
      font-family: "SemiRegular";
      // word-wrap: break-word;
      // display: flex;
      // align-content: center;
      .el-table__expand-icon {
        position: relative;
      }
      span {
        word-wrap: break-word;
        word-break: break-all;
      }
      p {
        display: inline;
        word-wrap: break-word;
        word-break: break-all;
      }
    }
    /deep/ .el-table__row--level-1 {
      .cell {
        font-size: 15px;
        color: #666666;
      }

      .el-table_1_column_2 {
        .cell {
          padding-left: 35px;
        }
        .el-table__indent {
          padding-left: 0 !important;
        }
        .el-table__placeholder {
          width: 0;
        }
      }
    }
    /deep/ .el-icon-arrow-right {
      font-weight: 600;
    }
    /deep/ .el-icon-arrow-right:before {
      color: #333333;
    }
    /deep/ .el-table tbody tr:hover > td {
      background-color: #f6f6f6 !important;
    }
    /deep/ .select-box {
      // .select-row {
      //   background: #403f4f;
      // }

      .el-checkbox__inner {
        width: 16px;
        height: 16px;
        border-color: #adadad;
        // background-color: #403f4f;
      }
      .el-checkbox__inner::after {
        height: 8px;
        left: 5px;
      }
      .el-checkbox__inner::before {
        top: 6px;
        height: 3px;
      }
      .el-checkbox__input.is-indeterminate .el-checkbox__inner {
        background-color: transparent;
      }
      .is-checked {
        .el-checkbox__inner {
          border-color: #403f4f;
          background-color: #403f4f;
        }
      }
    }
    /deep/ .el-button--primary {
      color: #fff;
      background-color: #3f3f4f;
      border-color: #3f3f4f;
      padding: 10px 30px;
      font-weight: 500;
      font-family: "SemiBold" !important;
    }

    // /deep/ .rowSelect {
    //   background: #403f4f !important;
    //   border-color: #403f4f !important;
    //   color: #fff;
    // }
  }
}
// .button-wrap {
//   margin-top: 30px;
//   margin-left: 15px;
// }
</style>